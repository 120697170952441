import { Bankings, DistributionEmails, FavLessees, Introduction, OwnerLessor } from './components'
import ThirdPartyIntegration from './components/ThirdPartyIntegration'
import requireAuth from '../../utils/requireAuth'
import ContactList from './components/ContactList'
import RoleBasedAccess from '../../utils/RoleBasedAccess'

export const configRoutes = [
    {
        path:'configure/distribution',
        component:requireAuth(DistributionEmails),
        key:'DistributionEmails'
    },
    {
        path:'configure/introduction',
        component:requireAuth(Introduction),
        key:'Introduction'
    },
    {
        path:'configure/owner-lessor',
        component:requireAuth(OwnerLessor),
        key:'OwnerLessor'
    },
    {
        path:'configure/banking-details',
        component:requireAuth(Bankings),
        key:'Bankings'
    },
    {
        path:'configure/favourite-lessee',
        component:requireAuth(FavLessees),
        key:'FavLessees'
    },
    {
        path:'configure/third-party-integration',
        component:requireAuth(ThirdPartyIntegration),
        key:'ThirdPartyIntegration'
    },
    {
        path:'configure/contact-list',
        component:requireAuth(RoleBasedAccess(ContactList,['console','contact','R'])),
        key:'ThirdPartyIntegration'
    },
]